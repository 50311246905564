import { AppBar as MuiAppBar, Toolbar, IconButton, Box, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import Breadcrumbs from 'components/Breadcrumbs';
import UserMenu from 'components/UserMenu';
import TaskBar from 'components/TaskBar';
import theme from 'theme';

function AppBar(props){
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  return(
    <MuiAppBar
      elevation={0}
      position='fixed'
      sx={{
        background: '#E4E4E4', //theme.palette.highlight.main,
        color: '#353A40',
        borderBottom: '2px solid #009A7D',
        width:{sm: `calc(100% - ${props.drawerWidth}px)`}
      }}
    >
      <Toolbar variant='dense'>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={props.handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' }}}
        >
          <MenuIcon />
        </IconButton>
        {isMobile &&
          <Breadcrumbs/>
        }
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', paddingX: 2 }} >
          <TaskBar/>
        </Box>
        <UserMenu/>
      </Toolbar>
    </MuiAppBar>
  )
} export default AppBar;